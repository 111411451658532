<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
      <c-table
        ref="table1"
        title="단위작업 목록"
        :columns="grid1.columns"
        :data="investigation.sops"
        :gridHeight="gridHeight"
        :editable="editable&&!disabled"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="heaMuscleSopId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable&&!disabled" 
              label="LBLREMOVE" icon="delete" 
              @btnClicked="deleteJob" />
            <c-btn 
              v-if="editable&&!disabled" 
              label="LBLADD" icon="add" 
              @btnClicked="addJob" />
            <c-btn 
              v-show="editable && !disabled" 
              :isSubmit="isSaveJob"
              :url="saveJobUrl"
              :param="investigation.sops"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveJob"
              @btnCallback="saveJobCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'workerIds'">
              <!-- isArray -->
            <c-multi-field 
              :editable="editable"
              :disabled="disabled"
              :userInfo="userInfo(props)"
              type="user"
              label="" 
              :name="`workers${props.rowIndex}`" 
              v-model="props.row[col.name]"
              @dataChange="dataChange(props, col)" />
          </template>
          <template v-else-if="col.name==='detail'">
            <q-chip
              v-if="props.row.editFlag !== 'C'"
              outline square
              color="blue"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click.stop="rowClick(props.row)">
              ▶
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
      <c-table
        ref="table2"
        :title="`[${selectedJob.sopName}] 부담작업 목록`"
        :columns="grid2.columns"
        :data="detailJobs"
        :gridHeight="gridHeight"
        :editable="editable&&!disabled"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="false"
        :columnSetting="false"
        selection="multiple"
        rowKey="heaMuscleHeavyWorkId"
        @table-data-change="tableDataChange"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable && !disabled && isSelect" 
              label="평가결과 갱신" icon="build"
              @btnClicked="updateAssessResult" />
            <c-btn 
              v-if="editable&&!disabled&&isSelect" 
              label="LBLREMOVE" icon="delete" 
              @btnClicked="deleteDetailJob" />
            <c-btn 
              v-if="editable&&!disabled&&isSelect" 
              label="LBLADD" icon="add" 
              @btnClicked="addDetailJob" />
            <c-btn 
              v-show="editable && !disabled && isSelect" 
              :isSubmit="isSaveDetailJob"
              :url="saveDetailJobUrl"
              :param="detailJobs"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveDetailJob"
              @btnCallback="saveDetailJobCallback" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'musculoskeletalSystemJob',
  props: {
    investigation: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        plantCd: '',  // 사업장코드
        investigationPlanName: '',  // 조사계획명
        year: '',  // 심사년도
        heaInvestigationStepCd: '',  // 진행상태
        investigationStartDate: '',  // 조사시작일
        investigationEndDate: '',  // 조사종료일
        period: [],
        deptCd: '',  // 담당자 부서
        userId: '',  // 담당자 id
        investigationDeptCd: '',  // 조사부서
        agency: '',  // 조사기관
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        sops: [], // 단위작업
        sopTable: [], // 작업분류표
      }),
    },
    popupParam: {
      type: Object,
      default: () => ({
        heaInvestigationPlanId: '',
      }),
    },
    heavyWorks: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid1: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '단위작업',
            align: 'left',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'workerIds',
            field: 'workerIds',
            label: '작업자',
            align: 'center',
            sortable: false,
            style: 'width:200px',
            type: 'custom'
          },
          {
            name: 'detail',
            field: 'detail',
            label: '부담작업<br/>상세',
            align: 'center',
            sortable: false,
            style: 'width:65px',
            type: 'custom'
          },
        ],
      },
      grid2: {
        columns: [
          {
            name: 'heavyWorkName',
            field: 'heavyWorkName',
            label: '부담작업No',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'investigationDate',
            field: 'investigationDate',
            label: '조사일자',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'date',
          },
          {
            name: 'userName',
            field: 'userName',
            label: '조사자',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            type: 'user',
            userId: 'userId',
            // deptCd: 'investigationDeptCd,'
          },
          {
            required: true,
            name: 'detailJobContents',
            field: 'detailJobContents',
            label: '작업내용',
            align: 'left',
            sortable: false,
            style: 'width:180px',
            type: 'text'
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: '작업시간',
            align: 'center',
            sortable: false,
            style: 'width:70px',
            type: 'number'
          },
          {
            name: 'workCnt',
            field: 'workCnt',
            label: '횟수',
            align: 'center',
            sortable: false,
            style: 'width:70px',
            type: 'number'
          },
          {
            name: 'result',
            field: 'result',
            label: '결과',
            child: [
              {
                name: 'workTotal',
                field: 'workTotal',
                label: '최종<br/>노출시간',
                align: 'center',
                sortable: false,
                style: 'width:70px',
              },
              {
                name: 'assessResult',
                field: 'assessResult',
                label: '평가결과',
                align: 'center',
                sortable: false,
                style: 'width:80px',
              },
            ]
          },
        ],
      },
      selectedJob: {
        heaMuscleSopId: '',
        mdmSopId: '',
        sopName: '',
      },
      editable: true,
      isSaveJob: true,
      isSaveDetailJob: true,
      listJobUrl: '',
      listDetailJobUrl: '',
      saveJobUrl: '',
      deleteJobUrl: '',
      saveDetailJobUrl: '',
      deleteDetailJobUrl: '',
      saveDeleteDetailJobUrl: '',
      popupOptions: {
        isFull: false,
        width: '65%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? (this.contentHeight - 100) + 'px' : '500px'
    },
    detailJobs() {
      let _items = [];
      if (this.investigation.sops && this.investigation.sops.length > 0 && this.selectedJob.mdmSopId) {
        let _data = this.$_.find(this.investigation.sops, { mdmSopId: this.selectedJob.mdmSopId });
        if (_data) {
          _items = _data.heavyWorks
        }
      }
      return _items;
    },
    isSelect() {
      return Boolean(this.selectedJob.heaMuscleSopId)
    },
    // userInfo() {
    //   return {
    //     userItems: [],
    //     userText: 'userName',
    //     userValue: 'userId',
    //   }
    // },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listJobUrl = selectConfig.hea.muscleSystem.muscleSop.list.url
      this.saveJobUrl = transactionConfig.hea.muscleSystem.muscleSop.save.url
      this.deleteJobUrl = transactionConfig.hea.muscleSystem.muscleSop.delete.url
      this.listDetailJobUrl = selectConfig.hea.muscleSystem.muscleHeavyWork.list.url
      this.saveDetailJobUrl = transactionConfig.hea.muscleSystem.muscleHeavyWork.save.url
      this.deleteDetailJobUrl = transactionConfig.hea.muscleSystem.muscleHeavyWork.delete.url
      this.saveDeleteDetailJobUrl = transactionConfig.hea.muscleSystem.muscleHeavyWork.savedelete.url
      // code setting
      // list setting
    },
    userInfo(props) {
      return {
        userItems: props.row.workers,
        userText: 'userName',
        userValue: 'userId',
      }
    },
    getJobs() {
      this.$http.url = this.listJobUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaInvestigationPlanId: this.popupParam.heaInvestigationPlanId
      }
      this.$http.request((_result) => {
        this.$set(this.investigation, 'sops', _result.data)
        /**
         * 부담작업 목록 clear
         */
        Object.assign(this.$data.selectedJob, this.$options.data().selectedJob);
      },);
    },
    getDetailJobs() {
      this.$http.url = this.listDetailJobUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaMuscleSopId: this.selectedJob.heaMuscleSopId
      }
      this.$http.request((_result) => {
        let job = this.$_.find(this.investigation.sops, { heaMuscleSopId: this.selectedJob.heaMuscleSopId });
        this.$set(job, 'heavyWorks', _result.data)
      },);
    },
    dataChange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name === 'workTime' || col.name === 'workCnt') {
        let _workTime = props.row['workTime'] && !isNaN(props.row['workTime']) ? Number(props.row['workTime']) : 0
        let _workCnt = props.row['workCnt'] && !isNaN(props.row['workCnt']) ? Number(props.row['workCnt']) : 0
        this.$set(props.row, 'workTotal', _workTime * _workCnt);

        if (this.heavyWorks && this.heavyWorks.length > 0) {
          let work = this.$_.find(this.heavyWorks, { heaHeavyWorkNo: props.row.heaHeavyWorkNo });
          // 과거시점
          let preAssessResult = props.row.assessResult;
          this.$set(props.row, 'assessResult', (props.row.workTotal >= (Number(work.exopsureCnt) * Number(work.exposureTime)) ? 'O' : 'X'));
          /**
           * assessResult의 기존값과 변경된 값을 비교한 뒤 변경된 row들만 editFlag = 'U'로 변경
           * 과거의 값에 대한 변수, 변경된 값에 대한 변수를 이용하여 비교
           * 둘의 값이 달라졌다면 editFlag를 'U'로 입력
           */
          // 바뀐시점
          let afterAssessResult = props.row.assessResult;
          if (preAssessResult !== afterAssessResult && props.row['editFlag'] !== 'C') {
            props.row['editFlag'] = 'U'
            props.row['chgUserId'] = this.$store.getters.user.userId
          }
        }
      }
    },
    updateAssessResult() {
      /**
       * 모든 행의 평가결과를 현재 부담작업 정보를 토대로 계산하는 로직
       */
      if (this.detailJobs && this.detailJobs.length > 0) {
        this.$_.forEach(this.detailJobs , detailJob => {
          this.tableDataChange({ row: detailJob }, { name: 'workTime' })
        })
      }
    },
    addJob() {
      this.popupOptions.title = '단위작업'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple',
        searchType: '1', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        // subProcessCd: this.process.processCd,
        // mdmSopId: props.row.mdmSopId,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeJobPopup;
    },
    closeJobPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.investigation.sops, { mdmSopId: item.mdmSopId }) === -1) {
            _data.push({
              heaMuscleSopId: uid(),
              heaInvestigationPlanId: this.popupParam.heaInvestigationPlanId,
              heaMuscleSopStepCd: 'HMS000001',  // 진행상태
              processCd: item.subProcessCd, processName: item.processName, 
              mdmSopId: item.mdmSopId, sopName: item.sopName,
              heaInvestigationTypeCd: null,  // 조사구분
              investigationStartDate: null,  // 조사일시
              deptCd: null,  // 조사자 부서
              userId: null,  // 조사자 id
              remark: '',  // 비고
              workers: [],
              heavyWorks: [],
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.investigation.sops = this.$_.concat(this.investigation.sops, _data)

        // this.$http.url = this.$format(this.saveJobUrl, this.popupParam.id);
        // this.$http.type = 'PUT';
        // this.$http.param = this.investigation.sops;
        // this.$http.request(() => {
        //   window.getApp.$emit('APP_REQUEST_SUCCESS');
        //   this.jobs();
        // },);
      }
    },
    deleteJob() {
      let selectData = this.$refs[`table1`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteJobUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getJobs();
              this.$refs[`table1`].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveJob() {      
      if (this.$comm.validTable(this.grid1.columns, this.investigation.sops)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까? 
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveJob = !this.isSaveJob
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('APP_VALID_ERROR');
      }
    },
    saveJobCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getJobs();
    },
    rowClick(row) {
      if (row.editFlag === 'C') {
        Object.assign(this.$data.selectedJob, this.$options.data().selectedJob);
      } else {
        this.$set(this.selectedJob, 'heaMuscleSopId', row.heaMuscleSopId)
        this.$set(this.selectedJob, 'mdmSopId', row.mdmSopId)
        this.$set(this.selectedJob, 'sopName', row.sopName)
      }
    },
    addDetailJob() {
      this.popupOptions.title = '부담작업'; // 부담작업
      this.popupOptions.param = {
        data: this.detailJobs, plantCd: this.investigation.plantCd
      };
      this.popupOptions.target = () => import(`${'./worksheetPop.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDetailJobPopup;
    },
    closeDetailJobPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        /**
         * 실제 저장/삭제 한 후 유해요인 조사 요청 하기전에 
         * 작업내용 및 평가결과 입력되어 있는지 판단하게 처리
         */
        /**
         * 추가
         */
        let _addData = [];
        for (let key in data[0]) {
          if (data[0][key] === 'Y' && 
            this.$_.findIndex(this.detailJobs, { heaHeavyWorkNo: key }) === -1) {
            _addData.push({
              heaMuscleHeavyWorkId: uid(),
              heaMuscleSopId: this.selectedJob.heaMuscleSopId,
              heaInvestigationPlanId: this.popupParam.heaInvestigationPlanId,
              mdmSopId: this.selectedJob.mdmSopId,
              plantCd: this.investigation.plantCd,
              heaHeavyWorkNo: key, // 부담작업No
              heavyWorkName: `${key}항`, // 부담작업No명 `${key}항`
              userId: this.$store.getters.user.userId, // 조사자
              userName: this.$store.getters.user.userName, // 조사자
              deptCd: this.$store.getters.user.deptCd, // 조사자 부서
              investigationDate: this.$comm.getToday(), // 조사일자
              detailJobContents: '', // 작업내용
              workTime: null, // 작업시간
              workCnt: null, // 작업횟수
              workTotal: null, // 총 노출시간
              assessResult: '', // 평가결과
              workload: null,  // 작업부하
              workFrequency: null,  // 작업빈도
              totalScore: null,  // 총 점수
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        }
        let _deleteData = [];
        this.$_.forEach(this.detailJobs, item => {
          if (this.$_.findIndex(Object.entries(data[0]), (_item) => {
            return String(_item[0]) === String(item.heaHeavyWorkNo) && _item[1] === 'N'
          }) > -1) {
            _deleteData.push(item)
            return
          }
        })
        let job = this.$_.find(this.investigation.sops, { heaMuscleSopId: this.selectedJob.heaMuscleSopId });
        // this.$set(job, 'heavyWorks', this.$_.concat(job.heavyWorks, _addData))

        /**
         * 삭제 처리
         */
        let _deleteDetailJobs = [];
        this.$_.forEach(_deleteData, item => {
          if (item.editFlag !== 'C') {
            _deleteDetailJobs.push(item)
          }
        })
        this.$http.url = this.saveDeleteDetailJobUrl;
        this.$http.type = 'PUT';
        this.$http.param = {
          saveHeavyWorks: this.$_.concat(job.heavyWorks, _addData),
          deleteHeavyWorks: _deleteDetailJobs,
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetailJobs();
        },);
      }
    },
    deleteDetailJob() {
      let selectData = this.$refs[`table2`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteDetailJobUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetailJobs();
              this.$refs[`table2`].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveDetailJob() {      
      if (this.$comm.validTable(this.grid2.columns, this.detailJobs)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?   
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveDetailJob = !this.isSaveDetailJob
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('APP_VALID_ERROR');
      }
    },
    saveDetailJobCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // this.getDetailJobs();
      this.$emit('getDetail')
    },
  }
};
</script>